import React, { createContext, lazy, Suspense, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { ThemeProvider } from "@mui/material/styles";
import { GET_LAYOUT_DATA, GET_TRANSLATIONS } from "const/ENDPOINTS";
import BreadcrumbsInterface, { BreadcrumbsContextInterface } from "interfaces/BreadcrumbsInterface";
import { ConfiguratorListItemInterface } from "interfaces/ConfiguratorListItemInterface";
import LayoutDataInterface, { LayoutDataContextInterface } from "interfaces/LayoutDataInterface";
import TranslationsInterface, { TranslationsContextInterface } from "interfaces/TranslationsInterface";
import theme from "themes/theme";
import { fetchJSON } from "utils/fetchUtils";
import { sendPageViewToGA } from "utils/gaUtils";
import {
  currentEnvId,
  danAquaConfigID,
  getConfiguratorListData,
  getConfiguratorTypes,
  getLanguageCookie,
  initEnvironment,
  initLanguage,
  setEnvironmentAsInternalAfterLogin,
  substationConfigID
} from "utils/layoutUtils";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "styles/css/homepage.css";
import "styles/css/tether.css";
import "styles/css/font-awesome.css";
import "styles/css/bootstrap.css";
import "styles/css/jquery.fileupload-ui.css";
import "styles/css/responsive.css";
import "styles/css/init.css";
import "styles/css/helpers.css";
import "styles/css/input.css";
import "styles/css/site.css";
import "styles/fonts.css";
import { GlobalStyle } from "styles/global.css";

const HeaderComponent = lazy(() => import("../Header/Header"));
const FooterComponent = lazy(() => import("../Footer/Footer"));
export const HomepageComponent = lazy(() => import("components/Home/Index/Index"));
const SearchComponent = lazy(() => import("components/Home/Search/Search"));
const LoginRouterComponent = lazy(() => import("components/Home/Login/LoginRouter"));
export const ConfiguratorListContainerComponent = lazy(
  () => import("components/Tool/ConfiguratorList/ConfiguratorListContainer")
);
export const ConfiguratorNewComponent = lazy(() => import("components/Tool/ConfiguratorNew/ConfiguratorNew"));
export const ToolListComponent = lazy(() => import("components/Tool/Tool/DanAqua/ToolList/ToolList"));
export const ToolComponent = lazy(() => import("components/Tool/Tool/DanAqua/Tool/Tool"));
export const SubstationSelectorComponent = lazy(
  () => import("components/Tool/Tool/Substation/SubstationSelector/SubstationSelector")
);
export const SubstationNavigatorComponent = lazy(
  () => import("components/Tool/Tool/Substation/SubstationNavigator/SubstationNavigator")
);
const QuoteListComponent = lazy(() => import("components/QuoteList/QuoteList"));
export const ConfiguratorToolsComponent = lazy(() => import("components/Tool/ConfiguratorTools/ConfiguratorTools"));
const SupportComponent = lazy(() => import("components/Home/Support/Support"));
const CookiesComponent = lazy(() => import("components/Home/Index/Cookies/Cookies"));

const DashboardComponent = lazy(() => import("components/Admin/Dashboard/Dashboard"));
const UserManagerComponent = lazy(() => import("components/Admin/UserManager/UserManager"));
const StatisticsComponent = lazy(() => import("components/Admin/Dashboard/UserStatistics/UserStatistics"));
const ChartComponent = lazy(() => import("components/Admin/Dashboard/ChartStatistics/ChartStatistics"));

const ResourceManagerComponent = lazy(() => import("components/Admin/ResourceManager/ResourceManager"));
const QuoteComponent = lazy(() => import("components/Tool/Quote/Quote"));
export const createSection = (component: any) => {
  return <>{createPortal(component, document.getElementById("ScrollContent"))}</>;
};

export const TranslationsContext = createContext<TranslationsContextInterface>(null);
export const BreadCrumbsContext = createContext<BreadcrumbsContextInterface>(null);
export const LayoutDataContext = createContext<LayoutDataContextInterface>(null);

const LayoutComponent: React.FC = () => {
  // TODO: Make context for passing common data (Proposal)
  const [translations, setTranslations] = useState<Array<TranslationsInterface>>();
  const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbsInterface>>();
  const [layoutData, setLayoutData] = useState<LayoutDataInterface>();
  const [loading, setLoading] = useState(true);
  const [loadingHeader, setLoadingHeader] = useState(true);
  const [, setLoadingFooter] = useState(true);
  const [configuratorTypes, setConfiguratorTypes] = useState();
  const [configuratorList, setConfiguratorList] = useState<ConfiguratorListItemInterface[]>([]);
  const [lang, setLang] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const configurationTools = false;

  const initData = async () => {
    initLanguage();
    initEnvironment();
    const layoutData = await fetchJSON(GET_LAYOUT_DATA, "GET");
    const configuratorTypes = await getConfiguratorTypes(currentEnvId());
    const configuratorList = await getConfiguratorListData(currentEnvId());
    const translations = await fetchJSON(GET_TRANSLATIONS(getLanguageCookie()), "GET");
    setTranslations(translations);
    setLayoutData(layoutData);
    setConfiguratorTypes(configuratorTypes);
    setConfiguratorList(configuratorList);
    setLang(layoutData.language);
    setBreadcrumbs([]);
    setEnvironmentAsInternalAfterLogin();
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (translations && layoutData && configuratorTypes) {
      setLoading(false);
    }
  }, [configuratorTypes, layoutData, translations]);

  useEffect(() => {
    if (window.location.pathname === "/") {
      sendPageViewToGA();
    }
  }, []);

  const clearAlertMessage = () => setAlertMessage("");

  const createSection = (component: any) => {
    return <>{createPortal(component, document.getElementById("ScrollContent"))}</>;
  };

  useEffect(() => {
    if (!loadingHeader) {
      document.getElementById("ScrollContent").style.opacity = "1";
    }
  }, [loadingHeader]);

  const translationContext = useMemo(() => ({ translations }), [translations]);
  const breadcrumbsContext = useMemo(() => ({ breadcrumbs, setBreadcrumbs }), [breadcrumbs]);

  const isSubstationEnv = useMemo(
    () => !!configuratorList.find((config: any) => config.ID === substationConfigID),
    [configuratorList]
  );

  const isDanAquaEnv = useMemo(
    () => !!configuratorList.find((config: any) => config.ID === danAquaConfigID),
    [configuratorList]
  );

  const layoutDataContext = useMemo(
    () => ({ layoutData, configuratorList, isSubstationEnv, isDanAquaEnv }),
    [configuratorList, isSubstationEnv, layoutData, isDanAquaEnv]
  );

  return (
    <ThemeProvider theme={theme}>
      {alertMessage && (
        <Alert severity="error" onClose={() => clearAlertMessage()}>
          {alertMessage}
        </Alert>
      )}
      {!loading && (
        <LayoutDataContext.Provider value={layoutDataContext}>
          <TranslationsContext.Provider value={translationContext}>
            <BreadCrumbsContext.Provider value={breadcrumbsContext}>
              <Suspense fallback="">
                <GlobalStyle />
                <>
                  {createPortal(
                    <HeaderComponent layoutData={layoutData} loading={setLoadingHeader} />,
                    document.getElementById("Header")
                  )}
                  <Router>
                    <Switch>
                      <Route exact path="/(Home|Home/Index)">
                        <Redirect to="/" />
                      </Route>
                      <Route path="/User/">
                        {createSection(
                          <LoginRouterComponent
                            currentUserLanguage={lang.toLowerCase()}
                            countries={layoutData.countries}
                          />
                        )}
                      </Route>
                      <Route exact path="/">
                        {createSection(<HomepageComponent configuratorTypes={configuratorTypes} />)}
                      </Route>
                      <Route path="/Admin/Dashboard">{createSection(<DashboardComponent />)}</Route>
                      <Route path="/Admin/ResourceManager">
                        {createSection(<ResourceManagerComponent language={lang} />)}
                      </Route>
                      <Route path="/Admin/UserManager">{createSection(<UserManagerComponent />)}</Route>
                      <Route path="/Admin/UserStatistics">{createSection(<StatisticsComponent />)}</Route>
                      <Route path="/Admin/ChartStatistics">{createSection(<ChartComponent />)}</Route>
                      <Route path="/Home/Search">{createSection(<SearchComponent />)}</Route>
                      <Route path="/Home/Support">{createSection(<SupportComponent language={lang} />)}</Route>
                      <Route path="/Home/Cookies">{createSection(<CookiesComponent language={lang} />)}</Route>
                      <Route exact path="/Tool/ConfiguratorList/:id?/:project?">
                        {createSection(
                          <ConfiguratorListContainerComponent configurationTools={configurationTools} language={lang} />
                        )}
                      </Route>
                      <Route exact path="/Tool/ToolList">
                        {createSection(<ToolListComponent />)}
                      </Route>
                      <Route path="/Tool/Tool/:id?">{createSection(<ToolComponent />)}</Route>
                      <Route path="/Tool/Dimensioning">{createSection(<ToolComponent />)}</Route>
                      <Route path="/Tool/Dimensioning/:name">{createSection(<ToolComponent />)}</Route>
                      <Route path="/Tool/Product/:name">{createSection(<ToolComponent />)}</Route>
                      <Route path="/Tool/SubstationSelector">{createSection(<SubstationSelectorComponent />)}</Route>
                      <Route path="/Tool/SubstationSelector/:combinationCode">
                        {createSection(<SubstationSelectorComponent />)}
                      </Route>
                      <Route path="/Tool/SubstationNavigator">{createSection(<SubstationNavigatorComponent />)}</Route>
                      <Route path="/Tool/NominalSize">{createSection(<ToolComponent />)}</Route>
                      <Route path="/Tool/WaterState">{createSection(<ToolComponent />)}</Route>
                      <Route path="/Tool/SteamState">{createSection(<ToolComponent />)}</Route>
                      <Route path="/Tool/ReturnDHW">{createSection(<ToolComponent />)}</Route>
                      <Route path="/Tool/ThermoDual_Recalculation">{createSection(<ToolComponent />)}</Route>
                      <Route path="/Tool/HEX_demo">{createSection(<ToolComponent />)}</Route>

                      <Route exact path="/Tool/ConfiguratorTools">
                        {createSection(<ConfiguratorToolsComponent language={lang} />)}
                      </Route>
                      <Route path="/Tool/ConfiguratorNew">{createSection(<ConfiguratorNewComponent />)}</Route>
                      <Route path="/Tool/Quote">
                        {createSection(<QuoteComponent language={lang} currentUser={layoutData.currentUser} />)}
                      </Route>
                      <Route path="/Tool/CalculationNew/:id/:project">
                        {createSection(<ConfiguratorNewComponent />)}
                      </Route>
                      <Route path="/Tool/QuoteList">{createSection(<QuoteListComponent />)}</Route>
                    </Switch>
                  </Router>
                  {createPortal(
                    <FooterComponent layoutData={layoutData} loading={setLoadingFooter} />,
                    document.getElementById("Footer")
                  )}
                </>
              </Suspense>
            </BreadCrumbsContext.Provider>
          </TranslationsContext.Provider>
        </LayoutDataContext.Provider>
      )}
    </ThemeProvider>
  );
};

const container = document.getElementById("Layout");
const root = createRoot(container);
root.render(<LayoutComponent />);
