import { createTheme, PaletteOptions, Shadows, ThemeOptions } from "@mui/material/styles";

const palette: PaletteOptions = {
  mode: "light",
  tonalOffset: 0.2,
  common: {
    white: "#FFFFFF",
    black: "#000000"
  },
  grey: {
    50: "#F1F3F5",
    100: "#E5E8EB",
    200: "#CCD3D8",
    300: "#B4BCC3",
    400: "#9DA7AF",
    500: "#869098"
  },
  primary: {
    main: "#B6000F",
    light: "#E2000F",
    dark: "#2c2636",
    contrastText: "#FFFFFF"
  },
  secondary: {
    main: "#1f61a3",
    light: "#869098",
    dark: "#303844",
    contrastText: "#FFFFFF"
  },
  success: {
    main: "#1AC632",
    light: "#1AC632",
    dark: "#1AC632",
    contrastText: "#000000"
  },
  info: {
    main: "#46b9f3",
    light: "#46b9f3",
    dark: "#46b9f3",
    contrastText: "#000000"
  },
  error: {
    main: "#E2000F",
    light: "#E2000F",
    dark: "#E2000F",
    contrastText: "#000000"
  },
  warning: {
    main: "#FE8A01",
    light: "#FE8A01",
    dark: "#FE8A01",
    contrastText: "#000000"
  },
  text: {
    primary: "#303844",
    secondary: "#70767E",
    disabled: "#869098"
  },
  divider: "#EBEBEB"
};

const typography = {
  fontFamily: "'myriad-pro', sans-serif",
  fontSizeLarge: "16px",
  fontSizeBase: "14px",
  fontSizeSmall: "12px",
  fontWeightRegular: 400,
  fontWeightBold: 600,
  fontWeightExtraBold: 800,
  lineHeightBase: 1.5,
  lineHeightSmall: 1,
  button: {
    fontFamily: "'myriad-pro', sans-serif",
    fontWeight: 600,
    lineHeight: 1.75,
    fontSize: "0.875rem",
    letterSpacing: "0.02857em",
    textTransform: "none"
  }
};

const breakpoints = {
  xs: 0,
  sm: "499px",
  md: "769px",
  lg: "992px"
};

const shadows = [
  "none",
  "0px 1px 3px -2px rgba(0,0,0,0.12),0px 1px 1px -1px rgba(0,0,0,0.08),0px 2px 1px -1px rgba(0,0,0,0.06)",
  "0px 1px 5px -2.5px rgba(0,0,0,0.12),0px 2px 2px 0px rgba(0,0,0,0.08),0px 3px 1px -2px rgba(0,0,0,0.06)",
  "0px 1px 8px -4px rgba(0,0,0,0.12),0px 3px 4px 0px rgba(0,0,0,0.08),0px 3px 3px -2px rgba(0,0,0,0.06)",
  "0px 2px 4px -1px rgba(0,0,0,0.12),0px 4px 5px 0px rgba(0,0,0,0.08),0px 1px 10px 0px rgba(0,0,0,0.06)",
  "0px 3px 5px -1px rgba(0,0,0,0.12),0px 5px 8px 0px rgba(0,0,0,0.08),0px 1px 14px 0px rgba(0,0,0,0.06)",
  "0px 3px 5px -1px rgba(0,0,0,0.12),0px 6px 10px 0px rgba(0,0,0,0.08),0px 1px 18px 0px rgba(0,0,0,0.06)",
  "0px 4px 5px -2px rgba(0,0,0,0.12),0px 7px 10px 1px rgba(0,0,0,0.08),0px 2px 16px 1px rgba(0,0,0,0.06)",
  "0px 5px 5px -3px rgba(0,0,0,0.12),0px 8px 10px 1px rgba(0,0,0,0.08),0px 3px 14px 2px rgba(0,0,0,0.06)",
  "0px 5px 6px -3px rgba(0,0,0,0.12),0px 9px 12px 1px rgba(0,0,0,0.08),0px 3px 16px 2px rgba(0,0,0,0.06)",
  "0px 6px 6px -3px rgba(0,0,0,0.12),0px 10px 14px 1px rgba(0,0,0,0.08),0px 4px 18px 3px rgba(0,0,0,0.06)",
  "0px 6px 7px -4px rgba(0,0,0,0.12),0px 11px 15px 1px rgba(0,0,0,0.08),0px 4px 20px 3px rgba(0,0,0,0.06)",
  "0px 7px 8px -4px rgba(0,0,0,0.12),0px 12px 17px 2px rgba(0,0,0,0.08),0px 5px 22px 4px rgba(0,0,0,0.06)",
  "0px 7px 8px -4px rgba(0,0,0,0.12),0px 13px 19px 2px rgba(0,0,0,0.08),0px 5px 24px 4px rgba(0,0,0,0.06)",
  "0px 7px 9px -4px rgba(0,0,0,0.12),0px 14px 21px 2px rgba(0,0,0,0.08),0px 5px 26px 4px rgba(0,0,0,0.06)",
  "0px 8px 9px -5px rgba(0,0,0,0.12),0px 15px 22px 2px rgba(0,0,0,0.08),0px 6px 28px 5px rgba(0,0,0,0.06)",
  "0px 8px 10px -5px rgba(0,0,0,0.12),0px 16px 24px 2px rgba(0,0,0,0.08),0px 6px 30px 5px rgba(0,0,0,0.06)",
  "0px 8px 11px -5px rgba(0,0,0,0.12),0px 17px 26px 2px rgba(0,0,0,0.08),0px 6px 32px 5px rgba(0,0,0,0.06)",
  "0px 9px 11px -5px rgba(0,0,0,0.12),0px 18px 28px 2px rgba(0,0,0,0.08),0px 7px 34px 6px rgba(0,0,0,0.06)",
  "0px 9px 12px -6px rgba(0,0,0,0.12),0px 19px 29px 2px rgba(0,0,0,0.08),0px 7px 36px 6px rgba(0,0,0,0.06)",
  "0px 10px 13px -6px rgba(0,0,0,0.12),0px 20px 31px 3px rgba(0,0,0,0.08),0px 8px 38px 7px rgba(0,0,0,0.06)",
  "0px 10px 13px -6px rgba(0,0,0,0.12),0px 21px 33px 3px rgba(0,0,0,0.08),0px 8px 40px 7px rgba(0,0,0,0.06)",
  "0px 10px 14px -6px rgba(0,0,0,0.12),0px 22px 35px 3px rgba(0,0,0,0.08),0px 8px 42px 7px rgba(0,0,0,0.06)",
  "0px 11px 14px -7px rgba(0,0,0,0.12),0px 23px 36px 3px rgba(0,0,0,0.08),0px 9px 44px 8px rgba(0,0,0,0.06)",
  "0px 11px 15px -7px rgba(0,0,0,0.12),0px 24px 38px 3px rgba(0,0,0,0.08),0px 9px 46px 8px rgba(0,0,0,0.06)"
] as Shadows;

const components = {
  MuiButton: {
    styleOverrides: {
      sizeMedium: {
        height: "50px"
      }
    }
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: "0 24px",
        minWidth: "500px",
        maxWidth: "min-content"
      }
    }
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: "8px 0"
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        "&&.Mui-selected": {
          backgroundColor: "rgba(31, 97, 163, 0.1)"
        },
        "&&.Mui-selected:hover": {
          backgroundColor: "rgba(31, 97, 163, 0.2)"
        }
      }
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      option: {
        "&[aria-selected='true']": {
          backgroundColor: "rgba(31, 97, 163, 0.1) !important"
        },
        "&[aria-selected='true']:hover": {
          backgroundColor: "rgba(31, 97, 163, 0.2) !important"
        }
      }
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: "#303844",
        fontSize: "14px",
        maxWidth: "400px"
      },
      arrow: {
        color: "#303844"
      }
    }
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        "&:has(> input:-webkit-autofill)": {
          backgroundColor: "rgb(232, 240, 254)"
        },
        paddingRight: "0px !important"
      }
    }
  }
};

const theme = createTheme({
  palette,
  typography,
  breakpoints,
  shadows,
  components
} as ThemeOptions);

export default theme;
