import { SEND_GA_GROUP_LIST, SEND_SEARCH_TRACK } from "const/ENDPOINTS";
import { fetchJSON } from "./fetchUtils";

const getSuffix = () => {
  const appIsDebug = window.APP_IS_DEBUG;
  return appIsDebug === "True" ? " | Test" : " | Production";
};

export const getCurrentEnvName = (envId: number) => {
  let ret = "";
  window.CurrentUser.EnvironmentList.forEach(function (i: any) {
    if (envId === i.ID) {
      ret = i.Name;
      return false;
    }
  });
  return ret;
};

export const sendPageViewToGAForTitle = (title: string) => {
  if (typeof window.ga === "undefined") return;
  window.ga("send", {
    hitType: "pageview",
    page: document.location.pathname,
    title: `${title}${getSuffix()}`
  });
};

export const sendPageViewToGA = () => {
  const title = `Index | ${window.CurrentUser.Language} | Login`;
  sendPageViewToGAForTitle(title);
};

export const sendPageViewToGAForGroupList = async (groupId: number, envName: string) => {
  if (typeof window.ga === "undefined") return;
  const data = { GroupId: groupId };

  try {
    const initData = await fetchJSON(SEND_GA_GROUP_LIST, "POST", data);

    if (initData.code === "200") {
      window.ga("send", {
        hitType: "pageview",
        page: document.location.pathname,
        title: `${initData.msg} | GroupList | ${envName}${getSuffix()}`
      });
    } else {
      window.ga("send", {
        hitType: "pageview",
        page: document.location.pathname,
        title: `Danfoss${getSuffix()}`
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendSearchTrack = (searchValue: string, title: string) => {
  if (typeof window.ga === "undefined") return;

  window.ga("send", {
    hitType: "pageview",
    page: `${SEND_SEARCH_TRACK}${searchValue.toLocaleLowerCase()}${getSuffix()}`,
    title: `${title}${getSuffix()}`
  });
};
