// TODO: import "GlobalStyle" in Layout: https://www.styled-components.com/docs/api#createglobalstyle

import { deviceSize } from "const/GRID_BREAKPOINT";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
   body {
    font-family: 'Myriad Pro', sans-serif;
    height: 100%;
    width: 100%;
  }

  .main-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  @media ${deviceSize.md} {
    .main-container {
      display: block;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
    }
  }

  .scroll-content {
    overflow: auto;
    position: relative;
    height: 100%;
  }

  a {
    cursor: pointer;
  }

  p {
    font-family: 'MyriadProRegular', sans-serif;
    margin: 0;
    padding: 0;
    padding-bottom: 22px;
    color: #303648;
    font-weight: normal;
  }

  h1 {
    font-size: 43px;
    color: #fff;
    padding: 0;
    margin: 0;
    line-height: 42px;
  }

  h2 {
    font-weight: 500;
    font-family: 'MyriadProBold', sans-serif;
    color: #303844;
    font-size: 24px;
    margin: 0;
    line-height: 36px;
    padding: 0;
    letter-spacing: 0px;
  }

  h3 {
    padding: 0;
  }

  h4 {
    padding: 0;
  }

  h5 {
    font-family: 'MyriadProBold', sans-serif;
    color: #303844;
    padding: 0;
    word-wrap: break-word;
    font-weight: bold;
    margin: 1rem 0;
    line-height: 1.1;
    letter-spacing: 0px;
    font-size: 16px;
    text-decoration: none;
  }
`;
