import { GET_CONFIGURATOR_LIST, GET_CONFIGURATOR_TYPES, POST_USER_SAVE_SETTING } from "const/ENDPOINTS";
import Cookies from "universal-cookie";
import { fetchJSON } from "./fetchUtils";

const cookies = new Cookies();
const DEFAULT_ENVIRONMENT = "DEFAULT_ENVIRONMENT";
const FIRST_DEFAULT_ENVIRONMENT_HAS_BEEN_INVOKED_BEFORE: string = localStorage.getItem(
  "FIRST_DEFAULT_ENVIRONMENT_HAS_BEEN_INVOKED_BEFORE"
);
export const DEFAULT_ENV = 2;

export const currentEnvId = (): number => {
  return parseInt(localStorage.getItem(DEFAULT_ENVIRONMENT), 10) || DEFAULT_ENV;
};

export const setEnvironmentStorage = (envId: number) => {
  return window.localStorage.setItem(DEFAULT_ENVIRONMENT, envId.toString());
};

export const clearEnvironmentStorage = () => {
  localStorage.removeItem(DEFAULT_ENVIRONMENT);
};

export const clearFirstEnvironmentLogin = () => {
  localStorage.removeItem("FIRST_DEFAULT_ENVIRONMENT_HAS_BEEN_INVOKED_BEFORE");
};
export const setEnvironmentAsInternalAfterLogin = () => {
  if (window.CurrentUser.DisplayName === "guest") {
    return;
  }

  if (FIRST_DEFAULT_ENVIRONMENT_HAS_BEEN_INVOKED_BEFORE !== null) {
    return;
  }

  let environmentItem = window.CurrentUser.EnvironmentList.find(x => x.Name === "Global Internal");

  if (environmentItem !== undefined) {
    setEnvironmentStorage(environmentItem.ID);
    localStorage.setItem("FIRST_DEFAULT_ENVIRONMENT_HAS_BEEN_INVOKED_BEFORE", "true");
    return;
  }

  environmentItem = window.CurrentUser.EnvironmentList.find(x => x.Name.includes("Internal"));

  if (environmentItem !== undefined) {
    setEnvironmentStorage(environmentItem.ID);
    localStorage.setItem("FIRST_DEFAULT_ENVIRONMENT_HAS_BEEN_INVOKED_BEFORE", "true");
  }
};

export const initLanguage = () => {
  if (!getLanguageCookie()) {
    setLanguageCookie(getLanguageCookie());
  }
  if (window.CurrentUser.UserName !== "guest") {
    setLanguageCookie(window.CurrentUser.Language);
  } else {
    setLanguageCookie(getLanguageCookie());
  }
};

export const initEnvironment = () => {
  if (!currentEnvId() || !isEnvironmentAvailable() || (isGuestAccount() && !isGuestEnvironmentCorrect())) {
    setEnvironment();
  }
};

const isGuestAccount = (): boolean => {
  return window.CurrentUser.DisplayName === "guest";
};

const setEnvironment = () => {
  const envId = getEnviromentIdByLanguage(getLanguageCookie());
  setEnvironmentStorage(envId);
};

const isEnvironmentAvailable = (): boolean => {
  const availableEnviroments = window.CurrentUser.EnvironmentList;
  if (availableEnviroments.length) {
    if (availableEnviroments.find(element => element.ID === currentEnvId())) {
      return true;
    }
  }
  return false;
};

const isGuestEnvironmentCorrect = (): boolean => {
  const availableEnviroments = window.CurrentUser.EnvironmentList;
  if (availableEnviroments.find(element => element.ID === currentEnvId() && element.Language === getLanguageCookie())) {
    return true;
  }
  return false;
};

export const setLanguageAndCountry = async (language: string, country: string = "", envId = 0) => {
  if (envId) {
    setEnvironmentStorage(envId);
  }
  setLanguageCookie(language);
  setCountryCookie(country);
  if (window.CurrentUser.UserName !== "guest") {
    await saveLanguageSettings(language);
  }
  window.location.reload();
};

export const getEnviromentIdByLanguage = (language: string) => {
  const availableEnviroments = window.CurrentUser.EnvironmentList;
  if (availableEnviroments.length) {
    const env = availableEnviroments.find(
      element => element.Language === language && element.Name.includes("External")
    );
    return env ? env.ID : availableEnviroments[0].ID;
  }
  return DEFAULT_ENV;
};

const saveUserSettings = async (settings: any) => {
  await fetchJSON(POST_USER_SAVE_SETTING, "POST", settings);
};

const saveLanguageSettings = async (uiLang: string, docLang: string = "") => {
  const settings = [
    { Name: "USER_INTERFACE_LANGUAGE", Value: uiLang },
    { Name: "USER_DOCUMENT_LANGUAGE", Value: docLang || uiLang }
  ];
  await saveUserSettings(settings);
};

const setLanguageCookie = (language: string) => {
  cookies.set("language", language, { path: "/" });
  return true;
};

const setCountryCookie = (country: string) => {
  cookies.set("country", country, { path: "/" });
  return true;
};

export const getLanguageCookie = (): string => {
  let languageCookie = cookies.get("language");
  if (!languageCookie) {
    languageCookie = getBrowserLocalization();
  }
  return languageCookie;
};

const getBrowserLocalization = () => {
  let val = "";
  const languages = [window.navigator.language];
  if (window.navigator.languages && window.navigator.languages.length > 0) {
    for (let i = 0; i < window.navigator.languages.length; i++)
      languages.push(window.navigator.languages[i].split("-")[0]);
  }
  if (languages[0] === "de") {
    val = "German";
  } else if (languages[0] === "pl") {
    val = "Polish";
  } else if (languages[0] === "zh") {
    val = "Chinese";
  } else if (languages[0] === "ru" || languages[0] === "be" || languages[0] === "uk") {
    val = "Russian";
  }
  if (!val) {
    val = "English";
  }
  return val;
};

export const setCookieAccepted = () => {
  const date = new Date();
  date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
  cookies.set("cookies-accepted", "1", { expires: date, path: "/" });
};

export const getConfiguratorTypes = (envId: number) => {
  return fetchJSON(GET_CONFIGURATOR_TYPES, "POST", {
    id: envId
  });
};

export const getConfiguratorListData = (envId: number) => {
  return fetchJSON(GET_CONFIGURATOR_LIST, "POST", {
    EnvironmentID: envId
  });
};

export const composeSize = (bytes: number) => {
  const r = [" B", " KB", " MB", " GB", " TB", " PB", " EB", " ZB", " YB"];
  let log = Math.floor(Math.log(bytes) / Math.log(1024));
  let size = Math.round(((bytes / 1024 ** log) * 2) / 2);
  if (size < 1) {
    log -= 1;
    size = Math.round((size * 1024 * 2) / 2);
  }
  return size + r[log];
};

export const substationConfigID = 24;
export const danAquaConfigID = 23;
