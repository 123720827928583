const GRID_BREAKPOINT = {
  SMALL: "576px",
  MEDIUM: "768px",
  LARGE: "992px",
  EXTRA_LARGE: "1200px",
  MEDIUM_LARGE: "1500px",
  EXTRA_EXTRA_LARGE: "1800px",
  EXTREMELY_LARGE: "1950px"
};

export default GRID_BREAKPOINT;

export const deviceSize = {
  sm: `(max-width: ${GRID_BREAKPOINT.SMALL})`,
  md: `(max-width: ${GRID_BREAKPOINT.MEDIUM})`,
  lg: `(max-width: ${GRID_BREAKPOINT.LARGE})`,
  xl: `(max-width: ${GRID_BREAKPOINT.EXTRA_LARGE})`,
  mxl: `(max-width: ${GRID_BREAKPOINT.MEDIUM_LARGE})`,
  xxl: `(max-width: ${GRID_BREAKPOINT.EXTRA_EXTRA_LARGE})`,
  xxxl: `(max-width: ${GRID_BREAKPOINT.EXTREMELY_LARGE})`
};
